import React from "react";
import {
  Col,
  Tabs,
  Tab,
  Row,
  Container,
  Form,
  Button,
  Table,
  Modal,
} from "react-bootstrap";
import iconBack from "../assets/icon/fi-rr-arrow-small-left.svg";
import Coba from "./Coba";
import GambarError from "../assets/icon/pixeltrue-vision-1 1.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Logobadak from "../assets/BadakBerjaya.svg";
import arrow from "../assets/icon/fi-rr-angle-small-right.svg";
import iconSurveiActive from "../assets/icon/Survei-Icon.svg";
import axios from "axios";

const Survei = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  });

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 700); // Sesuaikan dengan lebar layar yang sesuai
    }

    handleResize(); // Panggil sekali saat komponen dimuat untuk menentukan mode awal
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedOption, setSelectedOption] = useState("");

  const storeData = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("pilihan", selectedOption);
    try {
      const response = await axios.post(
        "https://api1.badakberjaya.online/api/Survei",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Sertakan token JWT di header Authorization
          },
        }
      );

      setShow(false);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  return (
    <div className="bodyMainPageV2">
      <div className="MainWrapper">
        {isMobile ? (
          <div className="Header-Wrapper-Mobile">
            <img src={Logobadak} className="Header-Mobile" />
            <div className="Header-Text-Mobile">Badak Berjaya</div>
          </div>
        ) : (
          <div>
            <Coba />
          </div>
        )}
        <Col className="backgroundAppV2">
          <div className="container-content-v2">
            <div onClick={() => navigate("/beranda")}>
              <h3 className="TittleSection mt-4">
                <img src={iconBack} className="me-2" />
                Survei
              </h3>
            </div>
            <h6>Silahkan isi survey yang ada dibawah ini</h6>

            <div className="Content mt-4">
              <Col>
                <div className="Notifikasi" onClick={handleShow}>
                  <div className="text-notifikasi">
                    <div>
                      <img className="me-2" src={iconSurveiActive}></img>
                    </div>
                    Isi survey
                  </div>
                  <div className="arrow-wrapper">
                    <img src={arrow}></img>
                  </div>
                </div>
              </Col>
            </div>
          </div>
        </Col>
      </div>

      {isMobile ? (
        <div className="NavigasiBottom">
          <Container>
            <Coba />
          </Container>
        </div>
      ) : (
        <div></div>
      )}

      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Survei</Modal.Title>
        </Modal.Header>
        <Form onSubmit={storeData}>
          <Modal.Body>
            Dari skala 1-5, Apakah anda puas dengan pelayanan kami?
            <Row>
              <Col>
                <Form.Check
                  className="Radio"
                  type="radio"
                  label="1 (Sangat Tidak Puas)"
                  value="1"
                  checked={selectedOption === "1"}
                  onChange={(e) => setSelectedOption(e.target.value)}
                />

                <Form.Check
                  className="Radio"
                  type="radio"
                  label="2 (Tidak Puas)"
                  value="2"
                  checked={selectedOption === "2"}
                  onChange={(e) => setSelectedOption(e.target.value)}
                />

                <Form.Check
                  className="Radio"
                  type="radio"
                  label="3 (Normal)"
                  value="3"
                  checked={selectedOption === "3"}
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                <Form.Check
                  className="Radio"
                  type="radio"
                  label="4 (Memuaskan)"
                  value="4"
                  checked={selectedOption === "4"}
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                <Form.Check
                  className="Radio"
                  type="radio"
                  label="5 (Sangat Memuaskan)"
                  value="5"
                  checked={selectedOption === "5"}
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="popupButton">
              <Button variant="danger" type="submit">
                Kirim
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Survei;
