import React from "react";
import {
  Col,
  Tabs,
  Tab,
  Row,
  Container,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import iconBack from "../assets/icon/fi-rr-arrow-small-left.svg";
import Coba from "./Coba";
import Logobadak from "../assets/BadakBerjaya.svg";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import moment from "moment";

const Antrian = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [tab_active, setTab_active] = useState("SIM");
  const currentDate = moment().format("D-M-YYYY");

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  });

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 700);
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTabs = (tab) => {
    setTab_active(tab);
  };

  return (
    <div>
      <div className="bodyMainPageV2">
        <div className="MainWrapper">
          {isMobile ? (
            <div className="Header-Wrapper-Mobile">
              <img src={Logobadak} className="Header-Mobile" />
              <div className="Header-Text-Mobile">Badak Berjaya</div>
            </div>
          ) : (
            <div>
              <Coba />
            </div>
          )}
          <Col className="backgroundAppV2">
            <div className="container-content-v2">
              <div onClick={() => navigate("/beranda")}>
                <h3 className="TittleSection mt-4">
                  <img src={iconBack} className="me-2" />
                  Antrian Berjalan
                </h3>
              </div>

              <h6>Berikut ini antrian yang sedang berjalan</h6>

              <div className="Tabs-container">
                <Col
                  className={
                    tab_active === "SIM" ? "Tab-1-active" : "Tab-1-unactive"
                  }
                  onClick={() => handleTabs("SIM")}
                >
                  SIM
                  <div
                    className={
                      tab_active === "SIM"
                        ? "Line-tab-active"
                        : "Line-tab-unactive"
                    }
                  ></div>
                </Col>
                <Col
                  className={
                    tab_active === "SKCK" ? "Tab-2-active" : "Tab-2-unactive"
                  }
                  onClick={() => handleTabs("SKCK")}
                >
                  SKCK
                  <div
                    className={
                      tab_active === "SKCK"
                        ? "Line-tab-active"
                        : "Line-tab-unactive"
                    }
                  ></div>
                </Col>
              </div>

              {tab_active === "SIM" && (
                <div className="Content mt-4">
                  <div className="Antrian-berjalan-user">
                    <img src={Logobadak} className="Logo-Antrian"></img>
                    <div className="text-urut-wrapper-antrian-user">
                      <div className="text-urut1-berjalan">
                        POLRES LAMPUNG TIMUR
                      </div>
                      <div className="text-urut2-berjalan">
                        No Urut Pelayanan SIM
                      </div>
                      <div className="text-urut3-berjalan">00</div>
                      <div className="text-urut2-berjalan">
                        Tanggal Pelayanan:
                      </div>
                      <div className="text-urut2-berjalan">{currentDate}</div>
                    </div>
                  </div>
                </div>
              )}

              {tab_active === "SKCK" && (
                <div className="Content mt-4">
                  <div className="Antrian-berjalan-user">
                    <img src={Logobadak} className="Logo-Antrian"></img>
                    <div className="text-urut-wrapper-antrian-user">
                      <div className="text-urut1-berjalan">
                        POLRES LAMPUNG TIMUR
                      </div>
                      <div className="text-urut2-berjalan">
                        No Urut Pelayanan SKCK
                      </div>
                      <div className="text-urut3-berjalan">00</div>
                      <div className="text-urut2-berjalan">
                        Tanggal Pelayanan:
                      </div>
                      <div className="text-urut2-berjalan">{currentDate}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </div>
      </div>

      {isMobile ? (
        <div className="NavigasiBottom">
          <Container>
            <Coba />
          </Container>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Antrian;
