import {
  Tabs,
  Tab,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Container,
} from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import AlurSim from "../assets/Alur_sim.png";
import Coba from "./Coba";
import iconBack from "../assets/icon/fi-rr-arrow-small-left.svg";
import { NavLink, useNavigate } from "react-router-dom";
import Logobadak from "../assets/BadakBerjaya.svg";
import axios from "axios";
import { Modal, Table, Pagination } from "react-bootstrap";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import erroricon from "../assets/404.svg";

const SimPageNew = () => {
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);
  const [jenis_permohonan, setJenis_permohonan] = useState("Baru");
  const [tgl_datang, setTgl_datang] = useState("");
  const [gol_sim, setGol_sim] = useState("");
  const [tinggi, setTinggi] = useState("");
  const [berkacamata, setBerkacamata] = useState("");
  const [cacat_fisik, setCacat_fisik] = useState("");
  const [sim, setSim] = useState("");
  const [foto_sim_lama, setFoto_sim_lama] = useState("");
  const [masa_berlaku_sim, setMasa_berlaku_sim] = useState("");
  const [surat_sehat, setSurat_sehat] = useState("");
  const [surat_psikologi, setSurat_psikologi] = useState("");
  const token = localStorage.getItem("token");
  const [dataRiwayat, setDataRiwayat] = useState([]);
  const [selectedRiwayat, setSelectedRiwayat] = useState(null);
  const [tab_active, setTab_active] = useState("Alur");
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 700); // Sesuaikan dengan lebar layar yang sesuai
    }

    handleResize(); // Panggil sekali saat komponen dimuat untuk menentukan mode awal
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  });

  const storeData = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("jenis_permohonan", jenis_permohonan);
    formData.append("tgl_datang", tgl_datang);
    formData.append("gol_sim", gol_sim);
    formData.append("tinggi", tinggi);
    formData.append("berkacamata", berkacamata);
    formData.append("cacat_fisik", cacat_fisik);
    formData.append("sim", sim);
    formData.append("foto_sim_lama", foto_sim_lama);
    formData.append("masa_berlaku_sim", masa_berlaku_sim);
    formData.append("surat_sehat", surat_sehat);
    formData.append("surat_psikologi", surat_psikologi);

    try {
      const response = await axios.post(
        "https://api1.badakberjaya.online/api/sim",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setShow(true);
      setValidation(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setValidation(error.response.data);
      console.log(error.response.data);
    }
  };

  const lihatRiwayat = async (e) => {
    try {
      const response = await axios.get(
        "https://api1.badakberjaya.online/api/Riwayat-Sim",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const riwayat = response.data;

      setDataRiwayat(riwayat);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);

  const handleShow2 = (riwayat) => {
    setSelectedRiwayat(riwayat);
    setShow2(true);
  };

  const handleTabs = (tab) => {
    setTab_active(tab);
    if (tab === "Riwayat") {
      lihatRiwayat();
    }
  };

  return (
    <div className="bodyMainPageV2">
      <div className="MainWrapper">
        {isMobile ? (
          <div className="Header-Wrapper-Mobile">
            <img src={Logobadak} className="Header-Mobile" />
            <div className="Header-Text-Mobile">Badak Berjaya</div>
          </div>
        ) : (
          <div>
            <Coba />
          </div>
        )}
        <Col className="backgroundAppV2">
          <div className="container-content-v2">
            <div onClick={() => navigate("/beranda")}>
              <h3 className="TittleSection mt-4 ">
                <img src={iconBack} className="me-2 " />
                SIM
              </h3>
            </div>
            <h6>Surat Izin Mengemudi</h6>
            <div className="Tabs-container">
              <Col
                className={
                  tab_active === "Alur" ? "Tab-1-active" : "Tab-1-unactive"
                }
                onClick={() => handleTabs("Alur")}
              >
                Alur
                <div
                  className={
                    tab_active === "Alur"
                      ? "Line-tab-active"
                      : "Line-tab-unactive"
                  }
                ></div>
              </Col>
              <Col
                className={
                  tab_active === "Pengajuan" ? "Tab-2-active" : "Tab-2-unactive"
                }
                onClick={() => handleTabs("Pengajuan")}
              >
                Pengajuan
                <div
                  className={
                    tab_active === "Pengajuan"
                      ? "Line-tab-active"
                      : "Line-tab-unactive"
                  }
                ></div>
              </Col>
              <Col
                className={
                  tab_active === "Riwayat" ? "Tab-3-active" : "Tab-3-unactive"
                }
                onClick={() => handleTabs("Riwayat")}
              >
                Riwayat
                <div
                  className={
                    tab_active === "Riwayat"
                      ? "Line-tab-active"
                      : "Line-tab-unactive"
                  }
                ></div>
              </Col>
            </div>

            {tab_active === "Alur" && (
              <div className="Content mt-4">
                <Col>
                  <strong>Alur pelayanan</strong>
                  <Col>
                    <img src={AlurSim} className="AlurWrapper mt-2" />
                  </Col>
                </Col>
                <Col>
                  <strong>Persyatan</strong>
                  <ol className="mt-2">
                    <li>Permohonan tertulis</li>
                    <li>Bisa membaca dan menulis</li>
                    <li>
                      Memiliki pengetahuan peraturan lalu lintas jalan dan
                      teknik dasar kendaraan bermotor.
                    </li>
                    <li>
                      Batas usia
                      <ul>
                        <li>17 Tahun untuk SIM Golongan C</li>
                        <li>17 Tahun untuk SIM Golongan A</li>
                        <li>20 Tahun untuk SIM Golongan BI / BII</li>
                      </ul>
                    </li>
                    <li>Terampil mengemudikan kendaraan bermotor</li>
                    <li>Sehat jasmani dan rohani</li>
                    <li>Lulus ujian teori dan praktek</li>
                  </ol>
                </Col>
              </div>
            )}

            {tab_active === "Pengajuan" && (
              <Form onSubmit={storeData}>
                <div className="Content mt-4">
                  <Col className="left-content">
                    <Form.Group
                      className="form"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Jenis Permohonan</Form.Label>
                      <div className="radio-wrapper">
                        <Col>
                          <Form.Check
                            className="Radio"
                            type="radio"
                            label="Baru"
                            value="Baru"
                            checked={jenis_permohonan === "Baru"}
                            onChange={(e) =>
                              setJenis_permohonan(e.target.value)
                            }
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            // onClick={() => alert("hello word")}
                            className="Radio"
                            type="radio"
                            label="Perpanjangan"
                            value="Perpanjangan"
                            checked={jenis_permohonan === "Perpanjangan"}
                            onChange={(e) =>
                              setJenis_permohonan(e.target.value)
                            }
                          />
                        </Col>
                      </div>
                    </Form.Group>

                    {jenis_permohonan === "Baru" && (
                      <div>
                        <Form.Group
                          className="form"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Tanggal Kedatangan</Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="Masukkan Tanggal Kedatangan"
                            onChange={(e) =>
                              setTgl_datang(
                                moment(e.target.value).format("DD-MM-YYYY")
                              )
                            }
                          />
                          {validation.tgl_datang && (
                            <small className="text-danger">
                              {validation.tgl_datang}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="form"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Golongan SIM</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => setGol_sim(e.target.value)}
                            value={gol_sim}
                          >
                            <option value="">Pilih Golongan SIM Anda</option>
                            <option value="A">SIM A</option>
                            <option value="B">SIM B</option>
                            <option value="C">SIM C</option>
                          </Form.Select>
                          {validation.gol_sim && (
                            <small className="text-danger">
                              {validation.gol_sim}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="form"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Tinggi Badan</Form.Label>
                          <InputGroup className="">
                            <Form.Control
                              type="number"
                              placeholder="Masukkan Tinggi Badan Anda"
                              aria-label="Masukkan tinggi badan anda"
                              style={{ textTransform: "capitalize" }}
                              onChange={(e) => setTinggi(e.target.value)}
                            />
                            <InputGroup.Text id="basic-addon2">
                              CM
                            </InputGroup.Text>
                          </InputGroup>
                          {validation.tinggi && (
                            <small className="text-danger">
                              {validation.tinggi}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className="form">Berkacamata</Form.Label>
                          <div className="radio-wrapper">
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="Iya"
                                value="Iya"
                                checked={berkacamata === "Iya"}
                                onChange={(e) => setBerkacamata(e.target.value)}
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="Tidak"
                                value="Tidak"
                                checked={berkacamata === "Tidak"}
                                onChange={(e) => setBerkacamata(e.target.value)}
                              />
                            </Col>
                          </div>
                          {validation.berkacamata && (
                            <small className="text-danger">
                              {validation.berkacamata}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className="form">Cacat Fisik</Form.Label>
                          <div className="radio-wrapper">
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="Iya"
                                value="Iya"
                                checked={cacat_fisik === "Iya"}
                                onChange={(e) => setCacat_fisik(e.target.value)}
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="Tidak"
                                value="Tidak"
                                checked={cacat_fisik === "Tidak"}
                                onChange={(e) => setCacat_fisik(e.target.value)}
                              />
                            </Col>
                          </div>
                          {validation.cacat_fisik && (
                            <small className="text-danger">
                              {validation.cacat_fisik}
                            </small>
                          )}
                        </Form.Group>
                      </div>
                    )}

                    {jenis_permohonan === "Perpanjangan" && (
                      <div>
                        <Form.Group
                          className="form"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Tanggal Kedatangan</Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="Masukkan Tanggal Kedatangan"
                            onChange={(e) =>
                              setTgl_datang(
                                moment(e.target.value).format("DD-MM-YYYY")
                              )
                            }
                          />
                          {validation.tgl_datang && (
                            <small className="text-danger">
                              {validation.tgl_datang}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className="form">Golongan SIM</Form.Label>
                          <div className="radio-wrapper">
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="A"
                                value="A"
                                checked={gol_sim === "A"}
                                onChange={(e) => setGol_sim(e.target.value)}
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="C"
                                value="C"
                                checked={gol_sim === "C"}
                                onChange={(e) => setGol_sim(e.target.value)}
                              />
                            </Col>
                          </div>
                          {validation.gol_sim && (
                            <small className="text-danger">
                              {validation.gol_sim}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group controlId="formFile" className="form">
                          <Form.Label>Foto SIM Lama</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              setFoto_sim_lama(e.target.files[0])
                            }
                          />
                          <Form.Text id="passwordHelpBlock" muted>
                            Tipe berkas : pdf/jpeg/png, ukuran maksimal : 2MB
                          </Form.Text>
                          <div>
                            {validation.foto_sim_lama && (
                              <small className="text-danger">
                                {validation.foto_sim_lama}
                              </small>
                            )}
                          </div>
                        </Form.Group>

                        <Form.Group
                          className="form"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Masa Berlaku SIM Lama</Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="Masukkan masa berlaku sim anda"
                            onChange={(e) =>
                              setMasa_berlaku_sim(e.target.value)
                            }
                          />
                          {validation.masa_berlaku_sim && (
                            <small className="text-danger">
                              {validation.masa_berlaku_sim}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="form"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Tinggi Badan</Form.Label>
                          <InputGroup className="">
                            <Form.Control
                              type="number"
                              placeholder="Masukkan Tinggi Badan Anda"
                              aria-label="Masukkan tinggi badan anda"
                              style={{ textTransform: "capitalize" }}
                              onChange={(e) => setTinggi(e.target.value)}
                            />
                            <InputGroup.Text id="basic-addon2">
                              CM
                            </InputGroup.Text>
                          </InputGroup>
                          {validation.tinggi && (
                            <small className="text-danger">
                              {validation.tinggi}
                            </small>
                          )}
                        </Form.Group>
                      </div>
                    )}
                  </Col>
                  <Col className="right-content">
                    {jenis_permohonan === "Baru" && (
                      <div>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className="form">
                            Surat Izin Mengemudi
                          </Form.Label>
                          <div className="radio-wrapper">
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="Ada"
                                value="Ada"
                                checked={sim === "Ada"}
                                onChange={(e) => setSim(e.target.value)}
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="Tidak ada"
                                value="Tidak Ada"
                                checked={sim === "Tidak Ada"}
                                onChange={(e) => setSim(e.target.value)}
                              />
                            </Col>
                          </div>
                          {validation.sim && (
                            <small className="text-danger">
                              {validation.sim}
                            </small>
                          )}
                        </Form.Group>
                        <Form.Group controlId="formFile" className="form">
                          <Form.Label>Surat Keterangan Sehat</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={(e) => setSurat_sehat(e.target.files[0])}
                          />
                          <Form.Text id="passwordHelpBlock" muted>
                            Tipe berkas : pdf/jpeg/png, ukuran maksimal : 2MB
                          </Form.Text>
                          <div>
                            {validation.surat_sehat && (
                              <small className="text-danger">
                                {validation.surat_sehat}
                              </small>
                            )}
                          </div>
                        </Form.Group>

                        <Form.Group controlId="formFile" className="form">
                          <Form.Label>Surat Lulus Tes Psikologi</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              setSurat_psikologi(e.target.files[0])
                            }
                          />
                          <Form.Text id="passwordHelpBlock" muted>
                            Tipe berkas : pdf/jpeg/png, ukuran maksimal : 2MB
                          </Form.Text>
                          <div>
                            {validation.surat_psikologi && (
                              <small className="text-danger">
                                {validation.surat_psikologi}
                              </small>
                            )}
                          </div>
                        </Form.Group>
                      </div>
                    )}

                    {jenis_permohonan === "Perpanjangan" && (
                      <div>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className="form">Berkacamata</Form.Label>
                          <div className="radio-wrapper">
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="Iya"
                                value="Iya"
                                checked={berkacamata === "Iya"}
                                onChange={(e) => setBerkacamata(e.target.value)}
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="Tidak"
                                value="Tidak"
                                checked={berkacamata === "Tidak"}
                                onChange={(e) => setBerkacamata(e.target.value)}
                              />
                            </Col>
                          </div>
                          {validation.berkacamata && (
                            <small className="text-danger">
                              {validation.berkacamata}
                            </small>
                          )}
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className="form">Cacat Fisik</Form.Label>
                          <div className="radio-wrapper">
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="Iya"
                                value="Iya"
                                checked={cacat_fisik === "Iya"}
                                onChange={(e) => setCacat_fisik(e.target.value)}
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="Tidak"
                                value="Tidak"
                                checked={cacat_fisik === "Tidak"}
                                onChange={(e) => setCacat_fisik(e.target.value)}
                              />
                            </Col>
                          </div>
                          {validation.cacat_fisik && (
                            <small className="text-danger">
                              {validation.cacat_fisik}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className="form">
                            Surat Izin Mengemudi
                          </Form.Label>
                          <div className="radio-wrapper">
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="Ada"
                                value="Ada"
                                checked={sim === "Ada"}
                                onChange={(e) => setSim(e.target.value)}
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="Tidak ada"
                                value="Tidak Ada"
                                checked={sim === "Tidak Ada"}
                                onChange={(e) => setSim(e.target.value)}
                              />
                            </Col>
                          </div>
                          {validation.sim && (
                            <small className="text-danger">
                              {validation.sim}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group controlId="formFile" className="form">
                          <Form.Label>Surat Keterangan Sehat</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={(e) => setSurat_sehat(e.target.files[0])}
                          />
                          <Form.Text id="passwordHelpBlock" muted>
                            Tipe berkas : pdf/jpeg/png, ukuran maksimal : 2MB
                          </Form.Text>
                          <div>
                            {validation.surat_sehat && (
                              <small className="text-danger">
                                {validation.surat_sehat}
                              </small>
                            )}
                          </div>
                        </Form.Group>

                        <Form.Group controlId="formFile" className="form">
                          <Form.Label>Surat Lulus Tes Psikologi</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              setSurat_psikologi(e.target.files[0])
                            }
                          />
                          <Form.Text id="passwordHelpBlock" muted>
                            Tipe berkas : pdf/jpeg/png, ukuran maksimal : 2MB
                          </Form.Text>
                          <div>
                            {validation.surat_psikologi && (
                              <small className="text-danger">
                                {validation.surat_psikologi}
                              </small>
                            )}
                          </div>
                        </Form.Group>
                      </div>
                    )}
                    <div className="LoginButton">
                      <Button variant="primary" size="m" type="submit">
                        Kirim
                      </Button>
                    </div>
                  </Col>
                </div>
              </Form>
            )}

            {tab_active === "Riwayat" && (
              <div className="Content mt-4">
                <Col>
                  {dataRiwayat.length === 0 && (
                    <div className="Error-img-container">
                      <img src={erroricon} className="Error-img" />
                      <div>Anda tidak memiliki riwayat pendaftaran SIM</div>
                    </div>
                  )}

                  {dataRiwayat.length > 0 && (
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th className="Size-tulisan-table">No</th>
                          <th className="Size-tulisan-table">
                            Jenis Permohonan
                          </th>
                          <th className="Size-tulisan-table">Tanggal</th>
                          <th className="Size-tulisan-table">Detail</th>
                        </tr>
                      </thead>
                      {dataRiwayat.map((item, index) => {
                        const dateString = item.created_at;
                        const jamfix = moment
                          .tz(dateString, "Asia/Jakarta")
                          .format("DD-MM-YYYY");

                        return (
                          <tbody key={index}>
                            <tr>
                              <td className="Size-tulisan-table">
                                {index + 1}
                              </td>
                              <td className="Size-tulisan-table">
                                {item.jenis_permohonan}
                              </td>
                              <td className="Size-tulisan-table">{jamfix}</td>
                              <td>
                                <div className="popupButton">
                                  <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() => handleShow2(item)}
                                  >
                                    Lihat
                                  </Button>{" "}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </Table>
                  )}
                </Col>
              </div>
            )}
          </div>
        </Col>
      </div>

      {isMobile ? (
        <div className="NavigasiBottom">
          <Container>
            <Coba />
          </Container>
        </div>
      ) : (
        <div></div>
      )}
      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Pengajuan Berhasil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Silahkan akses menu notifikasi untuk melihat nomor urut antrian anda{" "}
        </Modal.Body>
        <Modal.Footer>
          <div className="popupButton" onClick={() => navigate("/notifikasi")}>
            <Button variant="primary" onClick={handleClose}>
              Menu Notifikasi
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Detail Informasi </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>
                <p>
                  <strong>Jenis Permohonan </strong>
                </p>
                <p>
                  <strong>Tanggal Kedatangan</strong>
                </p>
                <p>
                  <strong>Golongan sim</strong>
                </p>
                <p>
                  <strong>Tinggi</strong>
                </p>
                <p>
                  <strong>Berkacamata</strong>
                </p>
                <p>
                  <strong>Cacat Fisik</strong>
                </p>
                <p>
                  <strong>Sim</strong>
                </p>
              </Col>
              <Col>
                <p>: {selectedRiwayat?.jenis_permohonan}</p>
                <p>: {selectedRiwayat?.tgl_datang}</p>
                <p>: {selectedRiwayat?.gol_sim}</p>
                <p>: {selectedRiwayat?.tinggi}</p>
                <p>: {selectedRiwayat?.berkacamata}</p>
                <p>: {selectedRiwayat?.cacat_fisik}</p>
                <p>: {selectedRiwayat?.sim}</p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SimPageNew;
