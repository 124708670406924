import React from "react";
import {
  Container,
  Form,
  Row,
  Button,
  Col,
  Alert,
  Modal,
} from "react-bootstrap";
import LogoBadak from "../assets/BadakBerjaya.svg";
import { useState, useRef, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom/dist";
import axios from "axios";

const ForgotPassword = ({}) => {
  const [validation, setValidation] = useState(false);
  const [email, setEmail] = useState("");
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const formRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/");
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/Beranda");
    }
  });

  const storeData = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("email", email);

    try {
      const response = await axios.post(
        "https://api1.badakberjaya.online/api/password/email",
        formData
      );
      setSuccessMessage(response.data.message);
      setShowModal(true);
      formRef.current.reset();
      setEmail("");
      window.scrollTo(0, 0);
    } catch (error) {
      setValidation(error.response.data);
      console.log(error.response.data);
      setSuccessAlertVisible(true);
    }
  };

  return (
    <div className="BackrgoundLogin">
      <div className="BackrgoundOverlay">
        <div className="FormWrapper2">
          <div className="FormHeader">
            <h5>Silahkan masukkan email anda untuk memperbarui password</h5>
          </div>
          {successAlertVisible && (
            <Alert variant="danger">
              Pengguna dengan email tersebut tidak ditemukan
            </Alert>
          )}
          <Form ref={formRef} onSubmit={storeData}>
            <Form.Group
              className="form"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Email</Form.Label>
              <Form.Control
                size="md"
                type="text"
                placeholder="Contoh@gmail.com"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              {/* {validation.email && (
                <small className="text-primary">
                  Silahkan masukkan email anda
                </small>
              )} */}
            </Form.Group>

            <div className="LoginButton">
              <Button variant="primary" type="submit" size="m">
                Kirim
              </Button>
            </div>
          </Form>
          <p className="Login-sekarang">
            Sudah punya akun?{" "}
            <NavLink className="BuatSekarang" to="/">
              <a>Login sekarang</a>
            </NavLink>
          </p>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Email terkirim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Silahkan cek kembali email anda</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="popupButton">
            <Button variant="primary" onClick={handleCloseModal}>
              Kembali ke Login
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
