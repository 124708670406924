import React from "react";
import {
  Col,
  Tabs,
  Tab,
  Row,
  Container,
  Form,
  Button,
  Accordion,
  Modal,
  Alert,
} from "react-bootstrap";
import iconBack from "../assets/icon/fi-rr-arrow-small-left.svg";
import Coba from "./Coba";
import { NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Logobadak from "../assets/BadakBerjaya.svg";
import axios from "axios";
import moment from "moment";

const Profil = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [jenis_kelamin, setJenis_kelamin] = useState("");
  const [agama, setAgama] = useState("");
  const [pendidikan_terakhir, setPendidikan_terakhir] = useState("");
  const [tempat_lahir, setTempat_lahir] = useState("");
  const [tanggal_lahir, setTanggal_lahir] = useState("");
  const [pekerjaan, setPekerjaan] = useState("");
  const [alamat, setAlamat] = useState("");
  const [provinsi, setProvinsi] = useState("");
  const [provinsiTerpilihId, setProvinsiTerpilihId] = useState("");
  const [provinsiTerpilihNama, setProvinsiTerpilihNama] = useState("");
  const [kabupaten, setKabupaten] = useState("");
  const [kabupatenTerpilihId, setKabupatenTerpilihId] = useState("");
  const [kabupatenTerpilihNama, setKabupatenTerpilihNama] = useState("");
  const [kabupatenTerpilih, setKabupatenTerpilih] = useState("");
  const [kecamatan, setKecamatan] = useState("");
  const [kecamatanTerpilihId, setKecamatanTerpilihId] = useState("");
  const [kecamatanTerpilihNama, setKecamatanTerpilihNama] = useState("");
  const [desa, setDesa] = useState("");
  const [desaTerpilihId, setDesaTerpilihId] = useState("");
  const [desaTerpilihNama, setDesaTerpilihNama] = useState("");
  const [foto_profil, setFoto_profil] = useState("");
  const [fotoProfil, setFotoProfil] = useState("");
  const [sandiLama, setSandiLama] = useState("");
  const [sandiBaru, setSandiBaru] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [userData2, setUserData2] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow2(false);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const formRef = useRef(null);
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validation, setValidation] = useState(false);
  const [tab_active, setTab_active] = useState("Profil");

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  });

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 700); // Sesuaikan dengan lebar layar yang sesuai
    }

    handleResize(); // Panggil sekali saat komponen dimuat untuk menentukan mode awal
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchData();
    fetchData2();
    fetchProvinsi();
  }, []);

  useEffect(() => {
    const fetchKabupaten = async () => {
      const idprovinsi = provinsiTerpilihId;
      const idprovinsi2 = provinsiTerpilihNama;

      const url = `https://luckyfbr.github.io/api-wilayah-indonesia/api/regencies/${idprovinsi}.json`;

      const response = await fetch(url);
      const data = await response.json();
      setKabupaten(data);
    };

    fetchKabupaten();
  }, [provinsiTerpilihId]);

  useEffect(() => {
    const fetchKecamatan = async () => {
      const idkabupaten = kabupatenTerpilihId;
      const idkabupaten2 = kabupatenTerpilihNama;

      const url = `https://luckyfbr.github.io/api-wilayah-indonesia/api/districts/${idkabupaten}.json`;

      const response = await fetch(url);
      const data = await response.json();
      setKecamatan(data);
    };

    fetchKecamatan();
  }, [kabupatenTerpilihId]);

  useEffect(() => {
    const fetchDesa = async () => {
      const idkecamatan = kecamatanTerpilihId;
      const idkecamatan2 = kecamatanTerpilihNama;

      const url = `https://luckyfbr.github.io/api-wilayah-indonesia/api/villages/${idkecamatan}.json`;

      const response = await fetch(url);
      const data = await response.json();
      setDesa(data);
    };

    fetchDesa();
  }, [kecamatanTerpilihId]);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://api1.badakberjaya.online/api/me",
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUserData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await axios.post(
        "https://api1.badakberjaya.online/api/Profil",
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUserData2(response.data.profil);
      setFotoProfil(response.data.foto_profil);
    } catch (error) {
      console.log(error);
    }
  };

  const updateData = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("jenis_kelamin", jenis_kelamin);
    formData.append("agama", agama);
    formData.append("pendidikan_terakhir", pendidikan_terakhir);
    formData.append("tempat_lahir", tempat_lahir);
    formData.append("tanggal_lahir", tanggal_lahir);
    formData.append("pekerjaan", pekerjaan);
    formData.append("alamat", alamat);
    formData.append("provinsi", provinsiTerpilihNama);
    formData.append("kabupaten", kabupatenTerpilihNama);
    formData.append("kecamatan", kecamatanTerpilihNama);
    formData.append("desa", desaTerpilihNama);
    formData.append("foto_profil", foto_profil);

    try {
      const response = await axios.post(
        "https://api1.badakberjaya.online/api/Profil-Update",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setValidation(false);
      setShow(true);
    } catch (error) {
      console.log(error.response.data);
      setValidation(error.response.data);
    }
  };

  const fetchProvinsi = async () => {
    const response = await fetch(
      "https://luckyfbr.github.io/api-wilayah-indonesia/api/provinces.json"
    );
    const dataProvinsi = await response.json();
    setProvinsi(dataProvinsi);
  };

  const handleChangeProv = (e) => {
    const terpilih = JSON.parse(e.target.value);

    setProvinsiTerpilihId(terpilih.id);
    setProvinsiTerpilihNama(terpilih.nama);
  };

  const handleChangeKab = (e) => {
    const terpilih = JSON.parse(e.target.value);

    setKabupatenTerpilihId(terpilih.id);
    setKabupatenTerpilihNama(terpilih.nama);
  };

  const handleChangeKec = (e) => {
    const terpilih = JSON.parse(e.target.value);

    setKecamatanTerpilihId(terpilih.id);
    setKecamatanTerpilihNama(terpilih.nama);
  };

  const handleChangeDes = (e) => {
    const terpilih = JSON.parse(e.target.value);

    setDesaTerpilihId(terpilih.id);
    setDesaTerpilihNama(terpilih.nama);
  };

  const perbaruiPassword = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("sandiLama", sandiLama);
    formData.append("sandiBaru", sandiBaru);

    try {
      const response = await axios.post(
        "https://api1.badakberjaya.online/api/password/update",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSuccessMessage(response.data.message);
      setSuccessAlertVisible(true);
      setValidation(false);
      formRef.current.reset();
      setShow2(true);
    } catch (error) {
      setValidation(error.response.data);
      setSuccessAlertVisible(false);
      setErrorMessage(error.response.data.error);
    }
  };

  const handleTabs = (tab) => {
    setTab_active(tab);
  };

  return (
    <div>
      <div className="bodyMainPageV2">
        <div className="MainWrapper">
          {isMobile ? (
            <div className="Header-Wrapper-Mobile">
              <img src={Logobadak} className="Header-Mobile" />
              <div className="Header-Text-Mobile">Badak Berjaya</div>
            </div>
          ) : (
            <div>
              <Coba />
            </div>
          )}

          <Col className="backgroundAppV2">
            <div className="container-content-v2">
              <div onClick={() => navigate("/beranda")}>
                <h3 className="TittleSection mt-4">
                  <img src={iconBack} className="me-2" />
                  Profil
                </h3>
              </div>

              <div className="Tabs-container">
                <Col
                  className={
                    tab_active === "Profil" ? "Tab-1-active" : "Tab-1-unactive"
                  }
                  onClick={() => handleTabs("Profil")}
                >
                  Profil
                  <div
                    className={
                      tab_active === "Profil"
                        ? "Line-tab-active"
                        : "Line-tab-unactive"
                    }
                  ></div>
                </Col>
                <Col
                  className={
                    tab_active === "Lengkapi Data"
                      ? "Tab-2-active"
                      : "Tab-2-unactive"
                  }
                  onClick={() => handleTabs("Lengkapi Data")}
                >
                  Lengkapi Data
                  <div
                    className={
                      tab_active === "Lengkapi Data"
                        ? "Line-tab-active"
                        : "Line-tab-unactive"
                    }
                  ></div>
                </Col>
                <Col
                  className={
                    tab_active === "Ubah Sandi"
                      ? "Tab-3-active"
                      : "Tab-3-unactive"
                  }
                  onClick={() => handleTabs("Ubah Sandi")}
                >
                  Ubah Sandi
                  <div
                    className={
                      tab_active === "Ubah Sandi"
                        ? "Line-tab-active"
                        : "Line-tab-unactive"
                    }
                  ></div>
                </Col>
              </div>

              {tab_active === "Profil" && (
                <div className="Content mt-4">
                  {isMobile ? (
                    <div>
                      <div className="Wrapper-Gambar-profile">
                        <img className="Gambar-profile" src={fotoProfil}></img>

                        <h4>{userData.nickname}</h4>
                      </div>
                      <Accordion defaultActiveKey="">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Data Pribadi</Accordion.Header>
                          <Accordion.Body>
                            <strong>Nama Pengguna</strong>
                            <div>{userData.nickname}</div>

                            <strong>NIK</strong>
                            <div>{userData.nik}</div>

                            <strong>Nama Lengkap</strong>
                            <div>{userData.fullname}</div>

                            <strong>Nomor Telepon</strong>
                            <div>{userData.phone_number}</div>

                            <strong>Email</strong>
                            <div>{userData.email}</div>
                            {userData2 && (
                              <div>
                                <strong>Tempat tanggal Lahir</strong>
                                <div>
                                  {userData2.tempat_lahir ?? "-"}{" "}
                                  {userData2.tanggal_lahir}
                                </div>
                                <strong>Jenis Kelamin</strong>
                                <div>{userData2.jenis_kelamin ?? "-"}</div>

                                <strong>Agama</strong>
                                <div>{userData2.agama ?? "-"}</div>

                                <strong>Pendidikan Terakhir</strong>
                                <div>
                                  {userData2.pendidikan_terakhir ?? "-"}
                                </div>

                                <strong>Pekerjaan</strong>
                                <div>{userData2.pekerjaan ?? "-"}</div>
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Data Alamat</Accordion.Header>
                          <Accordion.Body>
                            {userData2 && (
                              <div>
                                <strong>Provinsi</strong>
                                <div>{userData2.provinsi ?? "-"}</div>

                                <strong>Kabupaten</strong>
                                <div>{userData2.kabupaten ?? "-"}</div>

                                <strong>Kecamatan</strong>
                                <div>{userData2.kecamatan ?? "-"}</div>

                                <strong>Desa/kelurahan</strong>
                                <div>{userData2.desa ?? "-"}</div>

                                <strong>Alamat</strong>
                                <div>{userData2.alamat ?? "-"}</div>
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  ) : (
                    <Col>
                      <div className="Profile-Wrapper">
                        <Row>
                          <div className="Wrapper-Gambar-profile">
                            <img
                              className="Gambar-profile"
                              src={fotoProfil}
                            ></img>

                            <h4>{userData.nickname}</h4>
                          </div>
                          <Col lg={6}>
                            <Row>
                              <strong>Data Pribadi</strong>
                              <Col lg={5}>
                                <div>Nama Pengguna :</div>
                                <div>NIK</div>
                                <div>Nama Lengkap</div>
                                <div>Nomor Telepon</div>
                                <div>Email</div>
                                <div>Tempat, Tanggal Lahir</div>
                                <div>Jenis Kelamin</div>
                                <div>Agama</div>
                                <div>Pendidikan Terakhir</div>
                                <div>Pekerjaan</div>
                              </Col>
                              {userData && (
                                <Col>
                                  <div>{userData.nickname}</div>
                                  <div>: {userData.nik}</div>
                                  <div>: {userData.fullname}</div>
                                  <div>: {userData.phone_number}</div>
                                  <div>: {userData.email}</div>

                                  {userData2 && (
                                    <div>
                                      <div>
                                        : {userData2.tempat_lahir ?? "-"}{" "}
                                        {userData2.tanggal_lahir}
                                      </div>
                                      <div>
                                        : {userData2.jenis_kelamin ?? "-"}
                                      </div>
                                      <div>: {userData2.agama ?? "-"}</div>
                                      <div>
                                        : {userData2.pendidikan_terakhir ?? "-"}
                                      </div>
                                      <div>: {userData2.pekerjaan ?? "-"}</div>
                                    </div>
                                  )}
                                </Col>
                              )}
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <strong>Data Alamat</strong>

                              <Col>
                                <div>Alamat</div>
                                <div>Provinsi</div>
                                <div>Kabupaten</div>
                                <div>Kecamatan</div>
                                <div>Desa/kelurahan</div>
                              </Col>

                              {userData2 && (
                                <Col>
                                  <div>: {userData2.provinsi ?? "-"}</div>
                                  <div>: {userData2.kabupaten ?? "-"}</div>
                                  <div>: {userData2.kecamatan ?? "-"}</div>
                                  <div>: {userData2.desa ?? "-"}</div>
                                  <div>: {userData2.alamat ?? "-"}</div>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  )}
                </div>
              )}

              {tab_active === "Lengkapi Data" && (
                <Form onSubmit={updateData}>
                  <div className="Content mt-4">
                    <Col className="left-content" lg="6" xs="12">
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label className="form">jenis Kelamin</Form.Label>
                        <div className="radio-wrapper">
                          <Col>
                            <Form.Check
                              className="Radio"
                              type="radio"
                              label="Laki-Laki"
                              value="Laki-Laki"
                              checked={jenis_kelamin === "Laki-Laki"}
                              onChange={(e) => setJenis_kelamin(e.target.value)}
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              className="Radio"
                              type="radio"
                              label="Perempuan"
                              value="Perempuan"
                              checked={jenis_kelamin === "Perempuan"}
                              onChange={(e) => setJenis_kelamin(e.target.value)}
                            />
                          </Col>
                        </div>
                        {validation.jenis_kelamin && (
                          <small className="text-danger">
                            {validation.jenis_kelamin}
                          </small>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Agama</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => setAgama(e.target.value)}
                          value={agama}
                        >
                          <option>Pilih Agama Anda</option>
                          <option value="Islam">Islam</option>
                          <option value="Kristen Katolik">
                            Kristen Katolik
                          </option>
                          <option value="Kristen Protestan">
                            Kristen Protestan
                          </option>
                          <option value="Hindu">Hindu</option>
                          <option value="Budha">Budha</option>
                          <option value="Konghucu">Konghucu</option>
                        </Form.Select>
                        {validation.agama && (
                          <small className="text-danger">
                            {validation.agama}
                          </small>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Pendidikan Terakhir</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) =>
                            setPendidikan_terakhir(e.target.value)
                          }
                          value={pendidikan_terakhir}
                        >
                          <option>Pilih Pendidikan terakhir</option>
                          <option value="Tidak bersekolah">
                            Tidak bersekolah
                          </option>
                          <option value="SD/Sederajat">SD/Sederajat</option>
                          <option value="SMP/Sederajat">SMP/Sederajat</option>
                          <option value="SMA/Sederajat">SMA/Sederajat</option>
                          <option value="D3">D3</option>
                          <option value="S1">S1</option>
                          <option value="S2">S2</option>
                          <option value="S3">S3</option>
                        </Form.Select>
                        {validation.pendidikan_terakhir && (
                          <small className="text-danger">
                            {validation.pendidikan_terakhir}
                          </small>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Tempat Lahir</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Masukkan tempat lahir anda"
                          style={{ textTransform: "capitalize" }}
                          onChange={(e) => setTempat_lahir(e.target.value)}
                        />
                        {validation.tempat_lahir && (
                          <small className="text-danger">
                            {validation.tempat_lahir}
                          </small>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Tanggal Lahir</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Masukkan tanggal lahir anda"
                          onChange={(e) =>
                            setTanggal_lahir(
                              moment(e.target.value).format("DD-MM-YYYY")
                            )
                          }
                        />
                        {validation.tanggal_lahir && (
                          <small className="text-danger">
                            {validation.tanggal_lahir}
                          </small>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Pekerjaan</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Masukkan pekerjaan anda"
                          style={{ textTransform: "capitalize" }}
                          onChange={(e) => setPekerjaan(e.target.value)}
                        />
                        {validation.pekerjaan && (
                          <small className="text-danger">
                            {validation.pekerjaan}
                          </small>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Alamat</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Masukkan pekerjaan anda"
                          style={{ textTransform: "capitalize" }}
                          onChange={(e) => setAlamat(e.target.value)}
                        />
                        {validation.alamat && (
                          <small className="text-danger">
                            {validation.alamat}
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="right-content">
                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Provinsi</Form.Label>
                        <Form.Select
                          value={JSON.stringify({
                            id: provinsiTerpilihId,
                            nama: provinsiTerpilihNama,
                          })}
                          onChange={handleChangeProv}
                        >
                          {provinsi &&
                            provinsi.map((prov) => (
                              <option
                                key={prov.id}
                                value={JSON.stringify({
                                  id: prov.id,
                                  nama: prov.name,
                                })}
                              >
                                {prov.name}
                              </option>
                            ))}
                        </Form.Select>
                        {validation.provinsi && (
                          <small className="text-danger">
                            {validation.provinsi}
                          </small>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Kabupaten</Form.Label>
                        <Form.Select
                          value={JSON.stringify({
                            id: kabupatenTerpilihId,
                            nama: kabupatenTerpilihNama,
                          })}
                          onChange={handleChangeKab}
                        >
                          {" "}
                          {kabupaten &&
                            kabupaten.map((kab) => (
                              <option
                                key={kab.id}
                                value={JSON.stringify({
                                  id: kab.id,
                                  nama: kab.name,
                                })}
                              >
                                {kab.name}
                              </option>
                            ))}
                        </Form.Select>
                        {validation.kabupaten && (
                          <small className="text-danger">
                            {validation.kabupaten}
                          </small>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Kecamatan</Form.Label>
                        <Form.Select
                          value={JSON.stringify({
                            id: kecamatanTerpilihId,
                            nama: kecamatanTerpilihNama,
                          })}
                          onChange={handleChangeKec}
                        >
                          {kecamatan &&
                            kecamatan.map((kec) => (
                              <option
                                key={kec.id}
                                value={JSON.stringify({
                                  id: kec.id,
                                  nama: kec.name,
                                })}
                              >
                                {kec.name}
                              </option>
                            ))}
                        </Form.Select>
                        {validation.kecamatan && (
                          <small className="text-danger">
                            {validation.kecamatan}
                          </small>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Desa/Kelurahan</Form.Label>
                        <Form.Select
                          value={JSON.stringify({
                            id: desaTerpilihId,
                            nama: desaTerpilihNama,
                          })}
                          onChange={handleChangeDes}
                        >
                          {desa &&
                            desa.map((des) => (
                              <option
                                key={des.id}
                                value={JSON.stringify({
                                  id: des.id,
                                  nama: des.name,
                                })}
                              >
                                {des.name}
                              </option>
                            ))}
                        </Form.Select>
                        {validation.desa && (
                          <small className="text-danger">
                            {validation.desa}
                          </small>
                        )}
                      </Form.Group>

                      <Form.Group controlId="formFile" className="form">
                        <Form.Label>Foto Profil</Form.Label>
                        <Form.Control
                          type="file"
                          onChange={(e) => setFoto_profil(e.target.files[0])}
                        />
                        {validation.foto_profil && (
                          <small className="text-danger">
                            {validation.foto_profil}
                          </small>
                        )}
                      </Form.Group>

                      <div className="LoginButton">
                        <Button variant="primary" size="m" type="submit">
                          Simpan
                        </Button>
                      </div>
                    </Col>
                  </div>
                </Form>
              )}

              {tab_active === "Ubah Sandi" && (
                <Form ref={formRef} onSubmit={perbaruiPassword}>
                  <div className="Content mt-4">
                    <Col lg="6" xs="12">
                      {successAlertVisible && (
                        <Alert variant="success">
                          Kata sandi berhasil dirubah
                        </Alert>
                      )}
                      {validation && !successAlertVisible && (
                        <Alert variant="danger">Kata sandi lama salah</Alert>
                      )}
                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Kata Sandi Lama</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Masukkan kata sandi lama anda"
                          onChange={(e) => setSandiLama(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Kata Sandi Baru</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Masukkan kata sandi baru anda"
                          onChange={(e) => setSandiBaru(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Konfirmasi Kata Sandi Baru</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Masukkan kata sandi baru anda"
                          onChange={(e) => setSandiBaru(e.target.value)}
                        />
                      </Form.Group>
                      <div className="LoginButton">
                        <Button variant="primary" size="m" type="submit">
                          Perbarui
                        </Button>
                      </div>
                    </Col>
                  </div>
                </Form>
              )}
            </div>
          </Col>
        </div>

        {isMobile ? (
          <div className="NavigasiBottom">
            <Container>
              <Coba />
            </Container>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Data berhasil disimpan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Anda sekarang bisa mengakses semua menu yang ada di sistem Badak
          Berjaya{" "}
        </Modal.Body>
        <Modal.Footer>
          <div className="popupButton" onClick={() => navigate("/beranda")}>
            <Button variant="primary" onClick={handleClose}>
              Kembali keberanda
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Profil;
