import React from "react";
import {
  Col,
  Tabs,
  Tab,
  Row,
  Container,
  Form,
  Button,
  Table,
  Modal,
} from "react-bootstrap";
import iconBack from "../assets/icon/fi-rr-arrow-small-left.svg";
import Coba from "./Coba";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Alert } from "react-bootstrap";
import Logobadak from "../assets/BadakBerjaya.svg";
import axios from "axios";
import moment from "moment-timezone";
import erroricon from "../assets/404.svg";

const KritikSaran = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [judul, setJudul] = useState();
  const [isi_pesan, setIsi_pesan] = useState();
  const [dataRiwayat, setDataRiwayat] = useState([]);
  const [selectedDataRiwayat, setSelectedDataRiwayat] = useState([]);
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [validation, setValidation] = useState(false);
  const token = localStorage.getItem("token");
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [tab_active, setTab_active] = useState("Isi Formulir");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (riwayat) => {
    setSelectedDataRiwayat(riwayat);
    setShow(true);
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  });

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 700);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const storeData = async (e) => {
    e.preventDefault();

    const data = {
      judul: judul,
      isi_pesan: isi_pesan,
    };

    try {
      const response = await axios.post(
        "https://api1.badakberjaya.online/api/kritik",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Sertakan token JWT di header Authorization
          },
        }
      );
      setSuccessMessage(response.data.message);
      setSuccessAlertVisible(true);
      formRef.current.reset();
      setJudul("");
      setIsi_pesan("");
      setValidation(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setValidation(error.response.data);
      console.log(error.response.data);
    }
  };

  const lihatRiwayat = async (e) => {
    try {
      const response = await axios.get(
        "https://api1.badakberjaya.online/api/Riwayat-Kritik",
        {
          headers: {
            Authorization: `Bearer ${token}`, // Sertakan token JWT di header Authorization
          },
        }
      );
      const riwayat = response.data;
      setDataRiwayat(riwayat);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleTabs = (tab) => {
    setTab_active(tab);
    if (tab === "Riwayat") {
      lihatRiwayat();
    }
  };

  return (
    <div>
      <div className="bodyMainPageV2">
        <div className="MainWrapper">
          {isMobile ? (
            <div className="Header-Wrapper-Mobile">
              <img src={Logobadak} className="Header-Mobile" />
              <div className="Header-Text-Mobile">Badak Berjaya</div>
            </div>
          ) : (
            <div>
              <Coba />
            </div>
          )}
          <Col className="backgroundAppV2">
            <div className="container-content-v2">
              <div onClick={() => navigate("/beranda")}>
                <h3 className="TittleSection mt-4">
                  <img src={iconBack} className="me-2" />
                  Kritik & Saran
                </h3>
              </div>

              <h6>
                Silahkan berikan kritik dan saran untuk polres lampung timur
              </h6>

              <div className="Tabs-container">
                <Col
                  className={
                    tab_active === "Isi Formulir"
                      ? "Tab-1-active"
                      : "Tab-1-unactive"
                  }
                  onClick={() => handleTabs("Isi Formulir")}
                >
                  Isi Formulir
                  <div
                    className={
                      tab_active === "Isi Formulir"
                        ? "Line-tab-active"
                        : "Line-tab-unactive"
                    }
                  ></div>
                </Col>
                <Col
                  className={
                    tab_active === "Riwayat" ? "Tab-2-active" : "Tab-2-unactive"
                  }
                  onClick={() => handleTabs("Riwayat")}
                >
                  Riwayat
                  <div
                    className={
                      tab_active === "Riwayat"
                        ? "Line-tab-active"
                        : "Line-tab-unactive"
                    }
                  ></div>
                </Col>
              </div>

              {tab_active === "Isi Formulir" && (
                <div className="Content mt-4">
                  <Col lg="6" xs="12">
                    {successAlertVisible && (
                      <Alert variant="success">{successMessage}</Alert>
                    )}
                    <Form ref={formRef} onSubmit={storeData}>
                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Judul</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Masukkan judul"
                          onChange={(e) => setJudul(e.target.value)}
                        />
                        {validation.judul && (
                          <small className="text-danger">
                            Silahkan masukkan judul
                          </small>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="form"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Isi Pesan</Form.Label>
                        <Form.Control
                          placeholder="Masukkan pesan anda disini"
                          as="textarea"
                          rows={9}
                          onChange={(e) => setIsi_pesan(e.target.value)}
                        />
                        {validation.isi_pesan && (
                          <small className="text-danger">
                            Silahkan masukkan pesan yang ingin andai sampaikan
                          </small>
                        )}
                      </Form.Group>

                      <div className="LoginButton">
                        <Button variant="primary" size="m" type="submit">
                          Kirim
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </div>
              )}

              {tab_active === "Riwayat" && (
                <div className="Content mt-4">
                  <Col>
                    {dataRiwayat.length === 0 && (
                      <div className="Error-img-container">
                        <img src={erroricon} className="Error-img" />
                        <div>Anda tidak memiliki riwayat kritik dan saran</div>
                      </div>
                    )}

                    {dataRiwayat.length > 0 && (
                      <Table bordered responsive>
                        <thead>
                          <tr>
                            <th className="Size-tulisan-table">No</th>
                            <th className="Size-tulisan-table">Judul</th>
                            <th className="Size-tulisan-table">Tanggal</th>
                            <th className="Size-tulisan-table">Detail</th>
                          </tr>
                        </thead>
                        {dataRiwayat.map((item, index) => {
                          const dateString = item.created_at;
                          const jamfix = moment
                            .tz(dateString, "Asia/Jakarta")
                            .format("DD-MM-YYYY");

                          return (
                            <tbody key={index}>
                              <tr>
                                <td className="Size-tulisan-table">
                                  {index + 1}
                                </td>
                                <td className="Size-tulisan-table">
                                  {item.judul}
                                </td>
                                <td className="Size-tulisan-table">{jamfix}</td>
                                <td>
                                  <div className="popupButton">
                                    <Button
                                      variant="primary"
                                      size="sm"
                                      onClick={() => handleShow(item)}
                                    >
                                      Lihat
                                    </Button>{" "}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </Table>
                    )}
                  </Col>
                </div>
              )}
            </div>
          </Col>
        </div>
      </div>

      {isMobile ? (
        <div className="NavigasiBottom">
          <Container>
            <Coba />
          </Container>
        </div>
      ) : (
        <div></div>
      )}
      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Detail Informasi </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>
                <p>
                  <strong>Judul</strong>
                </p>
                <p>
                  <strong>Isi Pesan</strong>
                </p>
              </Col>
              <Col>
                <p>: {selectedDataRiwayat?.judul}</p>
                <p>: {selectedDataRiwayat?.isi_pesan}</p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default KritikSaran;
