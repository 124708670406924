import React from "react";
import {
  Col,
  Tabs,
  Tab,
  Row,
  Container,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import iconBack from "../assets/icon/fi-rr-arrow-small-left.svg";
import Coba from "./Coba";
import { NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Logobadak from "../assets/BadakBerjaya.svg";

const InfoAplikasi = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  });

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 700);
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="bodyMainPageV2">
        <div className="MainWrapper">
          {isMobile ? (
            <div className="Header-Wrapper-Mobile">
              <img src={Logobadak} className="Header-Mobile" />
              <div className="Header-Text-Mobile">Badak Berjaya</div>
            </div>
          ) : (
            <div>
              <Coba />
            </div>
          )}
          <Col className="backgroundAppV2">
            <div className="container-content-v2">
              <div onClick={() => navigate("/beranda")}>
                <h3 className="TittleSection mt-4">
                  <img src={iconBack} className="me-2" />
                  Info Aplikasi
                </h3>
              </div>

              <div className="Content mt-4">
                <Col>
                  <strong>Dasar Hukum</strong>
                  <ul>
                    <li>
                      Comander wish program 100 hari kerja kapolri tentang
                      peningkatan kualitas layanan publik polri
                    </li>
                    <li>
                      Surat edaran menteri komunikasi dan informatika republik
                      indonesia nomor 3 tahun 2016 tentang penyediaan layanan
                      aplikasi dan/atau konten melalui internet (over the top)
                    </li>
                    <li>
                      Keputusan kepala kepolisian resor Lampung Timur nomor :
                      Sprin/1192/XI/2020, tanggal 07 desember 2020 tentang
                      pembentukan dan pengangkaran pejabat pengadaan barang dan
                      jasa
                    </li>
                    <li>
                      Naskah perjanjin hibah daerah kabupaten Lampung Timur
                      nomor : 900/03/NPHD/27-SK/2021 tanggal 30 april 2021
                    </li>
                  </ul>
                  <strong>Anggaran/Sarana dan Prasarana</strong>
                  <ul>
                    <li>
                      Anggaran yang digunakan bersumber dari hibah pemerintah
                      kabupaten lampung timur tahun anggaran 2021 sebesar Rp.
                      80.000.000,- (Delapan puluh juta rupiah)
                    </li>
                    <li>
                      Anggaran pemeliharaan bersumber dari DIPA Polres Lampung
                      Timur T.A 2022
                    </li>
                  </ul>
                  <strong>Sumber Daya Manusia</strong>
                  <ul>
                    <li>
                      Surat perintah kepala kepolisian resor lampung timur nomor
                      : Sprin/VI/2021,tanggal 20 juni 2021 tentang petugas
                      pelayanan pada gedung pelayanan terpadu satu atap Polres
                      Lampung Timur
                    </li>
                  </ul>
                </Col>
              </div>
            </div>
          </Col>
        </div>
      </div>
      {isMobile ? (
        <div className="NavigasiBottom">
          <Container>
            <Coba />
          </Container>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default InfoAplikasi;
