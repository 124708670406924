import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import LogoBadak from "../assets/BadakBerjaya.svg";
import iconHome from "../assets/icon/New Icon/Home.png";
import iconNotifikasi from "../assets/icon/New Icon/Notification.png";
import iconUser from "../assets/icon/New Icon/Profile.png";
import iconLogout from "../assets/icon/New Icon/logout.png";
import Button from "react-bootstrap/Button";
import { useNavigate, useLocation } from "react-router-dom";
import iconHomeDesktop from "../assets/icon/New Icon/HomeDesktop.png";
import iconNotifikasiDesktop from "../assets/icon/New Icon/NotificationDesktop.png";
import iconUserDesktop from "../assets/icon/New Icon/ProfileDesktop.png";
import iconLogoutDesktop from "../assets/icon/New Icon/LogoutDesktop.png";

function Coba() {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [keluar, setKeluar] = useState(false);
  const popupClose = () => setKeluar(false);
  const popupShow = () => setKeluar(true);
  const [activeMenu, setActiveMenu] = useState(
    localStorage.getItem("activeMenu")
  );

  const location = useLocation();

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 700);
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <div className="NewNav">
      <Modal show={keluar} onHide={popupClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Konfirmasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>Apakah anda yakin akan keluar?</Modal.Body>
        <Modal.Footer>
          <div className="popupButton" onClick={handleLogout}>
            <Button variant="primary" size="m" type="submit">
              Keluar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {isMobile ? (
        <div>
          <div className="NavMobile">
            <Row>
              <Col className="NavMenuWrapper">
                <div
                  onClick={() => navigate("/beranda")}
                  className={
                    location.pathname === "/beranda" ||
                    location.pathname === "/Sim" ||
                    location.pathname === "/Skck" ||
                    location.pathname === "/KritikSaran" ||
                    location.pathname === "/Pengmas" ||
                    location.pathname === "/Survei" ||
                    location.pathname === "/InfoAplikasi" ||
                    location.pathname === "/Antrian"
                      ? "NavMenuWrapperAktif"
                      : "NavMenuWrapper"
                  }
                >
                  <img
                    className={
                      location.pathname === "/beranda" ||
                      location.pathname === "/Sim" ||
                      location.pathname === "/Skck" ||
                      location.pathname === "/KritikSaran" ||
                      location.pathname === "/Pengmas" ||
                      location.pathname === "/Survei" ||
                      location.pathname === "/InfoAplikasi" ||
                      location.pathname === "/Antrian"
                        ? "icon-active"
                        : "icon-default"
                    }
                    src={iconHome}
                  ></img>
                  <p>Beranda</p>
                </div>
              </Col>

              <Col className="NavMenuWrapper">
                <div
                  onClick={() => navigate("/notifikasi")}
                  className={
                    location.pathname === "/notifikasi"
                      ? "NavMenuWrapperAktif"
                      : "NavMenuWrapper"
                  }
                >
                  <img
                    className={
                      location.pathname === "/notifikasi"
                        ? "icon-active"
                        : "icon-default"
                    }
                    src={iconNotifikasi}
                  ></img>
                  <p>Notifikasi</p>
                </div>
              </Col>

              <Col className="NavMenuWrapper">
                <div
                  onClick={() => navigate("/profil")}
                  className={
                    location.pathname === "/profil"
                      ? "NavMenuWrapperAktif"
                      : "NavMenuWrapper"
                  }
                >
                  <img
                    className={
                      location.pathname === "/profil"
                        ? "icon-active"
                        : "icon-default"
                    }
                    src={iconUser}
                  ></img>
                  <p>Profil</p>
                </div>
              </Col>

              <Col className="NavMenuWrapper" onClick={popupShow}>
                <img className="icon-default" src={iconLogout}></img>
                <p>Keluar</p>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        // Tampilan untuk perangkat desktop (sidebar)
        <div className="MainBG">
          <div className="NavConatiner" id="navbar">
            <div className="NavWrapper">
              <div className="HeaderNavbar">
                <img src={LogoBadak} className="BadakImgNavbar" />
                <div className="HeaderText">BADAK BERJAYA</div>
              </div>
              <div className="Navbar">
                <ul className="list-unstyled">
                  <div
                    onClick={() => navigate("/beranda")}
                    className={
                      location.pathname === "/beranda" ||
                      location.pathname === "/Sim" ||
                      location.pathname === "/Skck" ||
                      location.pathname === "/KritikSaran" ||
                      location.pathname === "/Pengmas" ||
                      location.pathname === "/Survei" ||
                      location.pathname === "/InfoAplikasi" ||
                      location.pathname === "/Antrian"
                        ? "active"
                        : ""
                    }
                  >
                    <div className="NavIconWrapper">
                      <li className="NavText">
                        <img src={iconHomeDesktop} className="NavIcon" />
                        Beranda
                      </li>
                    </div>
                  </div>
                  <div
                    onClick={() => navigate("/notifikasi")}
                    className={
                      location.pathname === "/notifikasi" ? "active" : ""
                    }
                  >
                    <div className="NavIconWrapper">
                      <li className="NavText">
                        <img src={iconNotifikasiDesktop} className="NavIcon" />
                        Notifikasi
                      </li>
                    </div>
                  </div>
                  <div
                    onClick={() => navigate("/profil")}
                    className={location.pathname === "/profil" ? "active" : ""}
                  >
                    <div className="NavIconWrapper">
                      <li className="NavText">
                        <img src={iconUserDesktop} className="NavIcon" />
                        Profil
                      </li>
                    </div>
                  </div>
                  <div className="text-decoration-none">
                    <div className="NavIconWrapper" onClick={popupShow}>
                      <li className="NavText">
                        <img src={iconLogoutDesktop} className="NavIcon" />
                        Keluar
                      </li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Coba;
