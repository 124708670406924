import React from "react";
import { Col, Row, Container, Modal } from "react-bootstrap";
import iconBack from "../assets/icon/fi-rr-arrow-small-left.svg";
import Coba from "./Coba";
import { NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Logobadak from "../assets/BadakBerjaya.svg";
import arrow from "../assets/icon/fi-rr-angle-small-right.svg";
import iconNotifikasiActive from "../assets/icon/navNewIcon/icon-notifikasi-2.svg";
import axios from "axios";
import moment from "moment-timezone";
import erroricon from "../assets/404.svg";

const Notifikasi = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [dataNotifikasi, setDataNotifikasi] = useState([]);
  const [selectedNotifikasi, setSelectedNotifikasi] = useState(null);
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (nomorUrut) => {
    setSelectedNotifikasi(nomorUrut);
    setShow(true);
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  });

  const LihatNotifikasi = async (e) => {
    try {
      const response = await axios.get(
        "https://api1.badakberjaya.online/api/Notifikasi",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const notifikasi = response.data;
      setDataNotifikasi(notifikasi);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  useEffect(() => {
    LihatNotifikasi();

    function handleResize() {
      setIsMobile(window.innerWidth <= 700); // Sesuaikan dengan lebar layar yang sesuai
    }

    handleResize(); // Panggil sekali saat komponen dimuat untuk menentukan mode awal
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="bodyMainPageV2">
        <div className="MainWrapper">
          {isMobile ? (
            <div className="Header-Wrapper-Mobile">
              <img src={Logobadak} className="Header-Mobile" />
              <div className="Header-Text-Mobile">Badak Berjaya</div>
            </div>
          ) : (
            <div>
              <Coba />
            </div>
          )}
          <Col className="backgroundAppV2">
            <div className="container-content-v2">
              <div onClick={() => navigate("/beranda")}>
                <h3 className="TittleSection mt-4">
                  <img src={iconBack} className="me-2" />
                  Notifikasi
                </h3>
              </div>

              <div className="Content">
                <Col>
                  {dataNotifikasi.length === 0 && (
                    <div className="Error-img-container">
                      <img src={erroricon} className="Error-img" />
                      <div>Anda tidak memiliki notifikasi</div>
                    </div>
                  )}
                  {dataNotifikasi.length > 0 && (
                    <div className="notif-container">
                      {dataNotifikasi.map((item, index) => {
                        const dateString = item.created_at;
                        const jamfix = moment
                          .tz(dateString, "Asia/Jakarta")
                          .format("HH:mm");
                        return (
                          <Col
                            key={index}
                            className="mt-2"
                            onClick={() => handleShow(item)}
                          >
                            <div className="Notifikasi">
                              <div className="text-notifikasi">
                                <div>
                                  <img
                                    className="iconnotifikasi me-2"
                                    src={iconNotifikasiActive}
                                  ></img>
                                </div>
                                {item.message}
                              </div>
                              <Row className="arrow-wrapper">
                                <Col className="Time-notification">
                                  {jamfix}
                                </Col>
                                <Col>
                                  <img src={arrow}></img>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        );
                      })}
                    </div>
                  )}
                </Col>
              </div>
            </div>
          </Col>
        </div>
      </div>

      {isMobile ? (
        <div className="NavigasiBottom">
          <Container>
            <Coba />
          </Container>
        </div>
      ) : (
        <div></div>
      )}
      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Nomor antrian anda</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Antrian-user">
            <img src={Logobadak} className="Logo-Antrian"></img>
            <div className="text-urut-wrapper-antrian-user">
              <div className="text-urut1">POLRES LAMPUNG TIMUR</div>
              <div className="text-urut2">
                No Urut Pelayanan <>{selectedNotifikasi?.jenis}</>
              </div>
              <div className="text-urut3">{selectedNotifikasi?.no_urut}</div>
              <div className="text-urut2">Tanggal Pelayanan:</div>
              <div className="text-urut2">{selectedNotifikasi?.tgl_datang}</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Notifikasi;
