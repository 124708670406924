import React from "react";
import { Form, Button, Alert, Modal, Col, Row } from "react-bootstrap";

import { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom/dist";
import { useNavigate } from "react-router-dom/dist";
import axios from "axios";

const MakeAccount = ({ history }) => {
  const [validation, setValidation] = useState(false);
  const [nik, setNik] = useState("");
  const [fullname, setFullname] = useState("");
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [email, setEmail] = useState("");
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const formRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/");
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/Beranda");
    }
  });

  const storeData = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("nik", nik);
    formData.append("fullname", fullname);
    formData.append("nickname", nickname);
    formData.append("password", password);
    formData.append("phone_number", phone_number);
    formData.append("email", email);

    try {
      const response = await axios.post(
        "https://api1.badakberjaya.online/api/register",
        formData
      );
      console.log(response);
      setSuccessMessage(response.data.message);
      setSuccessAlertVisible(true);
      setShowModal(true);
      formRef.current.reset();
      setValidation(false);
      setNik("");
      setFullname("");
      setNickname("");
      setPassword("");
      setPhone_number("");
      setEmail("");
      window.scrollTo(0, 0);
    } catch (error) {
      setValidation(error.response.data);
      console.log(error.response.data);
    }
  };

  return (
    <div className="BackrgoundLogin">
      <div className="BackrgoundOverlay">
        <div className="FormWrapper">
          <div className="FormHeader">
            <h4>Buat akun anda sekarang</h4>
          </div>
          {successAlertVisible && (
            <Alert variant="success">{successMessage}</Alert>
          )}
          <Form ref={formRef} onSubmit={storeData}>
            <Row className="Row-form-2">
              <Col md={6}>
                <Form.Group className="form" controlId="validationCustom01">
                  <Form.Label>NIK (Nomor Induk Kependudukan)</Form.Label>
                  <Form.Control
                    size="md"
                    type="number"
                    placeholder="180715XXXXXXXX"
                    name="nik"
                    onChange={(e) => setNik(e.target.value)}
                  />
                  {validation.nik && (
                    <small className="text-danger">{validation.nik}</small>
                  )}
                </Form.Group>
                <Form.Group
                  className="form"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Nama Lengkap</Form.Label>
                  <Form.Control
                    size="md"
                    type="text"
                    placeholder="John Dalton"
                    name="fullname"
                    style={{ textTransform: "capitalize" }}
                    onChange={(e) => setFullname(e.target.value)}
                  />
                  {validation.fullname && (
                    <small className="text-danger">{validation.fullname}</small>
                  )}
                </Form.Group>
                <Form.Group
                  className="form"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Nama Pengguna</Form.Label>
                  <Form.Control
                    size="md"
                    type="text"
                    placeholder="JohnDalton11"
                    style={{ textTransform: "capitalize" }}
                    name="nickname"
                    onChange={(e) => setNickname(e.target.value)}
                  />
                  {validation.nickname && (
                    <small className="text-danger">{validation.nickname}</small>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="form"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Kata Sandi</Form.Label>
                  <Form.Control
                    size="md"
                    type="text"
                    placeholder="*************"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {validation.password && (
                    <small className="text-danger">{validation.password}</small>
                  )}
                </Form.Group>
                <Form.Group
                  className="form"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Nomor HP</Form.Label>
                  <Form.Control
                    size="md"
                    type="text"
                    placeholder="0857XXXXXXXX"
                    name="phone_number"
                    onChange={(e) => setPhone_number(e.target.value)}
                  />
                  {validation.phone_number && (
                    <small className="text-danger">
                      {validation.phone_number}
                    </small>
                  )}
                </Form.Group>
                <Form.Group
                  className="form"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    size="md"
                    type="email"
                    placeholder="Contoh@gmail.com"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {validation.email && (
                    <small className="text-danger">{validation.email}</small>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <div className="LoginButton">
              <Button variant="primary" type="submit" size="m">
                Daftar
              </Button>
            </div>
          </Form>
          <p className="Login-sekarang">
            Sudah punya akun?{" "}
            <NavLink className="BuatSekarang" to="/">
              <a>Login sekarang</a>
            </NavLink>
          </p>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Akun Berhasil Dibuat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Silahkan kembali kehalaman login untuk melanjutkan</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="popupButton">
            <Button variant="primary" onClick={handleCloseModal}>
              Kembali ke Login
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MakeAccount;
