import {
  Tabs,
  Tab,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Container,
} from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import SideNavbar from "../component/SideNavbar";
import AlurSkck from "../assets/Alur_skck.png";
import iconBack from "../assets/icon/fi-rr-arrow-small-left.svg";
import Coba from "./Coba";
import { NavLink, useNavigate } from "react-router-dom";
import Logobadak from "../assets/BadakBerjaya.svg";
import axios from "axios";
import moment from "moment-timezone";
import { Modal, Table, Pagination } from "react-bootstrap";
import erroricon from "../assets/404.svg";

const SkckPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  const [jenis_permohonan, setJenis_permohonan] = useState("Baru");
  const [tgl_datang, setTgl_datang] = useState("");
  const [foto_skck_lama, setFoto_skck_lama] = useState("");
  const [keperluan, setKeperluan] = useState("");
  const [rumus_sdk_jari_kanan, setRumus_sdk_jari_kanan] = useState("");
  const [rumus_sdk_jari_kiri, setRumus_sdk_jari_kiri] = useState("");
  const [pas_foto, setPas_foto] = useState("");
  const [cara_bayar, setCara_bayar] = useState("");
  const token = localStorage.getItem("token");
  const [dataRiwayat, setDataRiwayat] = useState([]);
  const [selectedRiwayat, setSelectedRiwayat] = useState(null);
  const [tab_active, setTab_active] = useState("Alur");
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 700); // Sesuaikan dengan lebar layar yang sesuai
    }

    handleResize(); // Panggil sekali saat komponen dimuat untuk menentukan mode awal
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  });

  const storeData = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("jenis_permohonan", jenis_permohonan);
    formData.append("tgl_datang", tgl_datang);
    formData.append("foto_skck_lama", foto_skck_lama);
    formData.append("keperluan", keperluan);
    formData.append("rumus_sdk_jari_kanan", rumus_sdk_jari_kanan);
    formData.append("rumus_sdk_jari_kiri", rumus_sdk_jari_kiri);
    formData.append("pas_foto", pas_foto);
    formData.append("cara_bayar", cara_bayar);

    try {
      const response = await axios.post(
        "https://api1.badakberjaya.online/api/skck",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setShow(true);
      setValidation(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setValidation(error.response.data);
      console.log(error.response.data);
    }
  };

  const lihatRiwayat = async (e) => {
    try {
      const response = await axios.get(
        "https://api1.badakberjaya.online/api/Riwayat-Skck",
        {
          headers: {
            Authorization: `Bearer ${token}`, // Sertakan token JWT di header Authorization
          },
        }
      );
      const riwayat = response.data;

      setDataRiwayat(riwayat);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);

  const handleShow2 = (riwayat) => {
    setSelectedRiwayat(riwayat);
    setShow2(true);
  };

  const handleTabs = (tab) => {
    setTab_active(tab);
    if (tab === "Riwayat") {
      lihatRiwayat();
    }
  };

  return (
    <div className="bodyMainPageV2">
      <div className="MainWrapper">
        {isMobile ? (
          <div className="Header-Wrapper-Mobile">
            <img src={Logobadak} className="Header-Mobile" />
            <div className="Header-Text-Mobile">Badak Berjaya</div>
          </div>
        ) : (
          <div>
            <Coba />
          </div>
        )}
        <Col className="backgroundAppV2">
          <div className="container-content-v2">
            <div onClick={() => navigate("/beranda")}>
              <h3 className="TittleSection mt-4">
                <img src={iconBack} className="me-2" />
                SKCK
              </h3>
            </div>
            <h6>Surat Keterangan Catatan Kepolisian</h6>
            <div className="Tabs-container">
              <Col
                className={
                  tab_active === "Alur" ? "Tab-1-active" : "Tab-1-unactive"
                }
                onClick={() => handleTabs("Alur")}
              >
                Alur
                <div
                  className={
                    tab_active === "Alur"
                      ? "Line-tab-active"
                      : "Line-tab-unactive"
                  }
                ></div>
              </Col>
              <Col
                className={
                  tab_active === "Pengajuan" ? "Tab-2-active" : "Tab-2-unactive"
                }
                onClick={() => handleTabs("Pengajuan")}
              >
                Pengajuan
                <div
                  className={
                    tab_active === "Pengajuan"
                      ? "Line-tab-active"
                      : "Line-tab-unactive"
                  }
                ></div>
              </Col>
              <Col
                className={
                  tab_active === "Riwayat" ? "Tab-3-active" : "Tab-3-unactive"
                }
                onClick={() => handleTabs("Riwayat")}
              >
                Riwayat
                <div
                  className={
                    tab_active === "Riwayat"
                      ? "Line-tab-active"
                      : "Line-tab-unactive"
                  }
                ></div>
              </Col>
            </div>

            {tab_active === "Alur" && (
              <div className="Content mt-4">
                <Col>
                  <strong>Alur pelayanan</strong>
                  <Col>
                    <img src={AlurSkck} className="AlurWrapper mt-2" />
                  </Col>
                </Col>
                <Col>
                  <strong>Persyaratan</strong>
                  <ol className="mt-2">
                    <li>
                      Membawa Surat Pengantar dari Kantor Kelurahan tempat
                      domisili pemohon
                    </li>
                    <li>
                      Membawa fotocopy KTP/SIM sesuai dengan domisili yang
                      tertera di surat pengantar dari Kantor Kelurahan
                    </li>
                    <li>Membawa fotocopy Kartu Keluarga.</li>
                    <li>Membawa fotocopy Akta Kelahiran/Kenal Lahir</li>
                    <li>
                      Membawa Pas Foto terbaru dan berwarna ukuran 4×6 sebanyak
                      6 lembar.
                    </li>
                    <li>
                      Mengisi Formulir Daftar Riwayat Hidup yang telah
                      disediakan di kantor Polisi dengan jelas dan bena
                    </li>
                    <li>Pengambilan Sidik Jari oleh petugas</li>
                  </ol>
                </Col>
              </div>
            )}

            {tab_active === "Pengajuan" && (
              <Form onSubmit={storeData}>
                <div className="Content mt-4">
                  <Col className="left-content" lg="6" xs="12">
                    <Form.Group
                      className="form"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Jenis Permohonan</Form.Label>
                      <div className="radio-wrapper">
                        <Col>
                          <Form.Check
                            className="Radio"
                            type="radio"
                            label="Baru"
                            value="Baru"
                            checked={jenis_permohonan === "Baru"}
                            onChange={(e) =>
                              setJenis_permohonan(e.target.value)
                            }
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            className="Radio"
                            type="radio"
                            label="Perpanjangan"
                            value="Perpanjangan"
                            checked={jenis_permohonan === "Perpanjangan"}
                            onChange={(e) =>
                              setJenis_permohonan(e.target.value)
                            }
                          />
                        </Col>
                      </div>
                    </Form.Group>

                    {jenis_permohonan === "Baru" && (
                      <div>
                        <Form.Group
                          className="form"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Tanggal Kedatangan</Form.Label>
                          <Form.Control
                            type="date"
                            placeholderFormat="DD-MM-YYYY"
                            onChange={(e) =>
                              setTgl_datang(
                                moment(e.target.value).format("DD-MM-YYYY")
                              )
                            }
                          />
                          {validation.tgl_datang && (
                            <small className="text-danger">
                              {validation.tgl_datang}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="form"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Keperluan</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => setKeperluan(e.target.value)}
                            value={keperluan}
                          >
                            <option>Pilih keperluan</option>
                            <option
                              value="Pencalonan Anggota Legislatif Tingkat
                                    Kabupaten/Kota"
                            >
                              Pencalonan Anggota Legislatif Tingkat
                              Kabupaten/Kota
                            </option>
                            <option value="Melamar Sebagai PNS">
                              Melamar Sebagai PNS
                            </option>
                            <option value="Melamar Sebagai Anggota TNI/Polri">
                              Melamar Sebagai Anggota TNI/Polri
                            </option>
                            <option value="Pencalonan Pejabat Publik">
                              Pencalonan Pejabat Publik
                            </option>
                            <option value="Kepemilikan Senjata Api">
                              Kepemilikan Senjata Api
                            </option>
                            <option value="Melamar Pekerjaan">
                              Melamar Pekerjaan
                            </option>
                            <option
                              value="Pencalonan Kepala Daerah Tingkat
                                    Kabupaten/Kota"
                            >
                              Pencalonan Kepala Daerah Tingkat Kabupaten/Kota
                            </option>
                            <option value="keperluan Lainnya">
                              keperluan Lainnya
                            </option>
                          </Form.Select>
                          {validation.keperluan && (
                            <small className="text-danger">
                              {validation.keperluan}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="form"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Rumus Sidik Jari Kanan</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Masukkan rumus"
                            onChange={(e) =>
                              setRumus_sdk_jari_kanan(e.target.value)
                            }
                          />
                          {validation.rumus_sdk_jari_kanan && (
                            <small className="text-danger">
                              {validation.rumus_sdk_jari_kanan}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="form"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Rumus Sidik Jari Kiri</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Masukkan rumus"
                            onChange={(e) =>
                              setRumus_sdk_jari_kiri(e.target.value)
                            }
                          />
                          {validation.rumus_sdk_jari_kiri && (
                            <small className="text-danger">
                              {validation.rumus_sdk_jari_kiri}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group controlId="formFile" className="form">
                          <Form.Label>Pas Foto</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={(e) => setPas_foto(e.target.files[0])}
                          />
                          <Form.Text id="passwordHelpBlock" muted>
                            Ukuran 4x6cm, latar belakang berwarna merah serta
                            maks file 2MB
                          </Form.Text>
                          <div>
                            {validation.pas_foto && (
                              <small className="text-danger">
                                {validation.pas_foto}
                              </small>
                            )}
                          </div>
                        </Form.Group>
                      </div>
                    )}

                    {jenis_permohonan === "Perpanjangan" && (
                      <div>
                        <Form.Group
                          className="form"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Tanggal Kedatangan</Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="Masukkan Tanggal Kedatangan"
                            onChange={(e) =>
                              setTgl_datang(
                                moment(e.target.value).format("DD-MM-YYYY")
                              )
                            }
                          />
                          {validation.tgl_datang && (
                            <small className="text-danger">
                              {validation.tgl_datang}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group controlId="formFile" className="form">
                          <Form.Label>Foto SKCK Lama</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              setFoto_skck_lama(e.target.files[0])
                            }
                          />
                          <div>
                            {validation.foto_skck_lama && (
                              <small className="text-danger">
                                {validation.foto_skck_lama}
                              </small>
                            )}
                          </div>
                        </Form.Group>

                        <Form.Group
                          className="form"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Keperluan</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => setKeperluan(e.target.value)}
                            value={keperluan}
                          >
                            <option>Pilih keperluan</option>
                            <option
                              value="Pencalonan Anggota Legislatif Tingkat
                                    Kabupaten/Kota"
                            >
                              Pencalonan Anggota Legislatif Tingkat
                              Kabupaten/Kota
                            </option>
                            <option value="Melamar Sebagai PNS">
                              Melamar Sebagai PNS
                            </option>
                            <option value="Melamar Sebagai Anggota TNI/Polri">
                              Melamar Sebagai Anggota TNI/Polri
                            </option>
                            <option value="Pencalonan Pejabat Publik">
                              Pencalonan Pejabat Publik
                            </option>
                            <option value="Kepemilikan Senjata Api">
                              Kepemilikan Senjata Api
                            </option>
                            <option value="Melamar Pekerjaan">
                              Melamar Pekerjaan
                            </option>
                            <option
                              value="Pencalonan Kepala Daerah Tingkat
                                    Kabupaten/Kota"
                            >
                              Pencalonan Kepala Daerah Tingkat Kabupaten/Kota
                            </option>
                            <option value="keperluan Lainnya">
                              keperluan Lainnya
                            </option>
                          </Form.Select>
                          {validation.keperluan && (
                            <small className="text-danger">
                              {validation.keperluan}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="form"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Rumus Sidik Jari Kanan</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Masukkan rumus"
                            onChange={(e) =>
                              setRumus_sdk_jari_kanan(e.target.value)
                            }
                          />
                          {validation.rumus_sdk_jari_kanan && (
                            <small className="text-danger">
                              {validation.rumus_sdk_jari_kanan}
                            </small>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="form"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Rumus Sidik Jari Kiri</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Masukkan rumus"
                            onChange={(e) =>
                              setRumus_sdk_jari_kiri(e.target.value)
                            }
                          />
                          {validation.rumus_sdk_jari_kiri && (
                            <small className="text-danger">
                              {validation.rumus_sdk_jari_kiri}
                            </small>
                          )}
                        </Form.Group>
                      </div>
                    )}
                  </Col>
                  <Col className="right-content" lg="6">
                    {jenis_permohonan === "Baru" && (
                      <div>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className="form">Cara Bayar</Form.Label>
                          <div className="radio-wrapper">
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                label="Loket"
                                value="Loket"
                                checked={cara_bayar === "Loket"}
                                onChange={(e) => setCara_bayar(e.target.value)}
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="Transfer (Rek BRI Harus a.n Pemohon)"
                                value="Transfer"
                                checked={cara_bayar === "Transfer"}
                                onChange={(e) => setCara_bayar(e.target.value)}
                              />
                            </Col>
                          </div>
                          {validation.cara_bayar && (
                            <small className="text-danger">
                              {validation.cara_bayar}
                            </small>
                          )}
                        </Form.Group>
                      </div>
                    )}

                    {jenis_permohonan === "Perpanjangan" && (
                      <div>
                        <Form.Group controlId="formFile" className="form">
                          <Form.Label>Pas Foto</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={(e) => setPas_foto(e.target.files[0])}
                          />
                          <Form.Text id="passwordHelpBlock" muted>
                            Ukuran 4x6cm, latar belakang berwarna merah serta
                            maks file 2MB
                          </Form.Text>
                          <div>
                            {validation.pas_foto && (
                              <small className="text-danger">
                                {validation.pas_foto}
                              </small>
                            )}
                          </div>
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className="form">Cara Bayar</Form.Label>
                          <div className="radio-wrapper">
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                label="Loket"
                                value="Loket"
                                checked={cara_bayar === "Loket"}
                                onChange={(e) => setCara_bayar(e.target.value)}
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                className="Radio"
                                type="Radio"
                                id="Radio"
                                label="Transfer (Rek BRI Harus a.n Pemohon)"
                                value="Transfer"
                                checked={cara_bayar === "Transfer"}
                                onChange={(e) => setCara_bayar(e.target.value)}
                              />
                            </Col>
                          </div>
                          {validation.cara_bayar && (
                            <small className="text-danger">
                              {validation.cara_bayar}
                            </small>
                          )}
                        </Form.Group>
                      </div>
                    )}
                    <div className="LoginButton">
                      <Button variant="primary" size="m" type="submit">
                        Kirim
                      </Button>
                    </div>
                  </Col>
                </div>
              </Form>
            )}

            {tab_active === "Riwayat" && (
              <div className="Content mt-4">
                <Col>
                  {dataRiwayat.length === 0 && (
                    <div className="Error-img-container">
                      <img src={erroricon} className="Error-img" />
                      <div>Anda tidak memiliki riwayat pendaftaran SKCK</div>
                    </div>
                  )}

                  {dataRiwayat.length > 0 && (
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th className="Size-tulisan-table">No</th>
                          <th className="Size-tulisan-table">
                            Jenis Permohonan
                          </th>
                          <th className="Size-tulisan-table">Tanggal</th>
                          <th className="Size-tulisan-table">Detail</th>
                        </tr>
                      </thead>
                      {dataRiwayat.map((item, index) => {
                        const dateString = item.created_at;
                        const jamfix = moment
                          .tz(dateString, "Asia/Jakarta")
                          .format("DD-MM-YYYY");

                        return (
                          <tbody key={index}>
                            <tr>
                              <td className="Size-tulisan-table">
                                {index + 1}
                              </td>
                              <td className="Size-tulisan-table">
                                {item.jenis_permohonan}
                              </td>
                              <td className="Size-tulisan-table">{jamfix}</td>
                              <td>
                                <div className="popupButton">
                                  <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() => handleShow2(item)}
                                  >
                                    Lihat
                                  </Button>{" "}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </Table>
                  )}
                </Col>
              </div>
            )}
          </div>
        </Col>
      </div>

      {isMobile ? (
        <div className="NavigasiBottom">
          <Container>
            <Coba />
          </Container>
        </div>
      ) : (
        <div></div>
      )}

      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Pengajuan Berhasil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Silahkan akses menu notifikasi untuk melihat nomor urut antrian anda{" "}
        </Modal.Body>
        <Modal.Footer>
          <div className="popupButton" onClick={() => navigate("/notifikasi")}>
            <Button variant="primary">Menu Notifikasi</Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Detail Informasi </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>
                <p>
                  <strong>Jenis Permohonan </strong>
                </p>
                <p>
                  <strong>Tanggal Kedatangan</strong>
                </p>
                <p>
                  <strong>Keperluan</strong>
                </p>
                <p>
                  <strong>Rumus Sidik Jari Kanan</strong>
                </p>
                <p>
                  <strong>Rumus Sidik Jari Kiri</strong>
                </p>
                <p>
                  <strong>Cara Bayar</strong>
                </p>
              </Col>
              <Col>
                <p>: {selectedRiwayat?.jenis_permohonan}</p>
                <p>: {selectedRiwayat?.tgl_datang}</p>
                <p>: {selectedRiwayat?.keperluan}</p>
                <p>: {selectedRiwayat?.rumus_sdk_jari_kanan}</p>
                <p>: {selectedRiwayat?.rumus_sdk_jari_kiri}</p>
                <p>: {selectedRiwayat?.cara_bayar}</p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SkckPage;
