import { Row, Col, Container, Button, Modal } from "react-bootstrap";

import simicon from "../assets/icon/navNewIcon/Menu-icon-sim.svg";
import skckicon from "../assets/icon/navNewIcon/Menu-icon-skck.svg";
import infoicon from "../assets/icon/navNewIcon/Menu-icon-info.svg";
import pelmasicon from "../assets/icon/navNewIcon/Menu-icon-pelaporan.svg";
import kritiksaranicon from "../assets/icon/navNewIcon/Menu-icon-kritik.svg";
import surveiicon from "../assets/icon/navNewIcon/Menu-icon-survey.svg";
import dupresicon from "../assets/icon/navNewIcon/Menu-icon-dumes.svg";
import antrianicon from "../assets/icon/navNewIcon/Menu-icon-antrian.svg";
import { useState, useEffect } from "react";
import Coba from "../pages/Coba";
import { NavLink } from "react-router-dom";
import Banner2 from "../assets/icon/Banner.jpg";
import Banner from "../assets/polres.png";
import Banner3 from "../assets/Kapolres.JPG";
import Logobadak from "../assets/BadakBerjaya.svg";
import { useNavigate } from "react-router-dom/dist";
import axios from "axios";
import PanicCall from "../assets/icon/navNewIcon/Menu-icon-telepon.svg";
import moment from "moment";

const Mainpage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userData2, setUserData2] = useState([]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [slideAktif, setSlideAktif] = useState(0);
  const [timer, setTimer] = useState(null);
  const [activeImage, setActiveImage] = useState(Banner);
  const [pointActive, setPointActive] = useState(0);
  const currentDate = moment().format("D-M-YYYY");

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  });

  useEffect(() => {
    fetchData();
    fetchData2();
    function handleResize() {
      setIsMobile(window.innerWidth <= 700);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://api1.badakberjaya.online/api/me",
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUserData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await axios.post(
        "https://api1.badakberjaya.online/api/Profil",
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUserData2(response.data.profil);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuClick = (menu) => {
    if (!userData2) {
      handleShow(true);
    } else if (menu === "SIM") {
      navigate("/Sim");
    } else if (menu === "SKCK") {
      navigate("/Skck");
    }
  };

  const handleMenuProfil = () => {
    navigate("/Profil");
  };

  const dataSlider = [
    { id: 1, gambar: Banner },
    { id: 2, gambar: Banner2 },
    { id: 3, gambar: Banner3 },
  ];

  const slideBerikutnya = () => {
    setSlideAktif((prev) => {
      if (prev < dataSlider.length - 1) return prev + 1;
      return 0;
    });

    setActiveImage(dataSlider[slideAktif].gambar);
    setPointActive(dataSlider[slideAktif].id);

    console.log("Gambar Aktif: ", activeImage);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      slideBerikutnya();
    }, 6000);

    return () => clearInterval(interval);
  }, [slideAktif]);

  return (
    <div className="bodyMainPage">
      <div className="MainWrapper">
        <div id="sidebar">
          <Coba />
        </div>

        <div className="backgroundApp">
          {/* <Container id="container1"> */}

          <div className="container-content">
            <h5 className="GreetingText">
              Selamat datang, {userData.fullname}!
            </h5>
            <div className="antrian-container">
              <div id="box">
                <div className="slider-wrapper">
                  <div className="wrapper-image-point">
                    <div
                      className={
                        pointActive === 1 ? "image-point-active" : "image-point"
                      }
                    ></div>
                    <div
                      className={
                        pointActive === 2 ? "image-point-active" : "image-point"
                      }
                    ></div>
                    <div
                      className={
                        pointActive === 3 ? "image-point-active" : "image-point"
                      }
                    ></div>
                  </div>
                  <img className="images" src={activeImage} />
                </div>
              </div>

              <div lg={5} className="vertical-line" id="box-antrian">
                <div className="section-antrian mb-2">
                  <img src={antrianicon} className="iconAntrian" />
                  <h4 className="ms-1">Nomor antrian berjalan</h4>
                </div>

                <div className="Row-Antrian">
                  <div>
                    <h6>SIM</h6>
                    <div className="Antrian-berjalan-user">
                      <img src={Logobadak} className="Logo-Antrian"></img>
                      <div className="text-urut-wrapper-antrian-user">
                        <div className="text-urut1-berjalan">
                          POLRES LAMPUNG TIMUR
                        </div>
                        <div className="text-urut2-berjalan">
                          No Urut Pelayanan SIM
                        </div>
                        <div className="text-urut3-berjalan">00</div>
                        <div className="text-urut2-berjalan">
                          Tanggal Pelayanan:
                        </div>
                        <div className="text-urut2-berjalan">{currentDate}</div>
                      </div>
                    </div>
                  </div>

                  <div className="ms-4">
                    <h6>SKCK </h6>
                    <div className="Antrian-berjalan-user">
                      <img src={Logobadak} className="Logo-Antrian"></img>
                      <div className="text-urut-wrapper-antrian-user">
                        <div className="text-urut1-berjalan">
                          POLRES LAMPUNG TIMUR
                        </div>
                        <div className="text-urut2-berjalan">
                          No Urut Pelayanan SKCK
                        </div>
                        <div className="text-urut3-berjalan">00</div>
                        <div className="text-urut2-berjalan">
                          Tanggal Pelayanan:
                        </div>
                        <div className="text-urut2-berjalan">{currentDate}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* </Container> */}
            <div className="horizontal-line" id="hr"></div>

            <h4 className="MenuSectionText" id="menuutama">
              Menu utama
            </h4>
          </div>
          {isMobile ? (
            <div className="container-menu-mobile">
              <div className="container-menu-mobile">
                <div className="wrapper-menu-mobile">
                  <Row className="Upper-Menu">
                    <Col className="MenuIconWrapper">
                      <div onClick={() => handleMenuClick("SIM")}>
                        <div>
                          <img src={simicon} className="MenuIcon" />
                          <h5 className="TextMenu">SIM</h5>
                        </div>
                      </div>
                    </Col>
                    <Col className="MenuIconWrapper">
                      <div onClick={() => handleMenuClick("SKCK")}>
                        <div>
                          <img src={skckicon} className="MenuIcon" />
                          <h5 className="TextMenu">SKCK</h5>
                        </div>
                      </div>
                    </Col>
                    <Col className="MenuIconWrapper">
                      <NavLink className="text-decoration-none" to="/Antrian">
                        <div>
                          <img src={antrianicon} className="MenuIcon" />
                          <h5 className="TextMenu">Antrian</h5>
                        </div>
                      </NavLink>
                    </Col>
                    <Col className="MenuIconWrapper">
                      <a
                        className="text-decoration-none"
                        href="https://dumaspresisi.polri.go.id/"
                      >
                        <div>
                          <img src={dupresicon} className="MenuIcon" />
                          <h5 className="TextMenu">Dupres</h5>
                        </div>
                      </a>
                    </Col>
                  </Row>
                  <Row className="Bottom-Menu">
                    <Col className="MenuIconWrapper">
                      <NavLink
                        className="text-decoration-none"
                        to="/KritikSaran"
                      >
                        <div>
                          <img src={kritiksaranicon} className="MenuIcon" />
                          <h5 className="TextMenu">Kritik Saran</h5>
                        </div>
                      </NavLink>
                    </Col>
                    <Col className="MenuIconWrapper">
                      <NavLink className="text-decoration-none" to="/Pengmas">
                        <div>
                          <img src={pelmasicon} className="MenuIcon" />
                          <h5 className="TextMenu">Pengaduan</h5>
                        </div>
                      </NavLink>
                    </Col>
                    <Col className="MenuIconWrapper">
                      <NavLink className="text-decoration-none" to="/Survei">
                        <div>
                          <img src={surveiicon} className="MenuIcon" />
                          <h5 className="TextMenu">Survei</h5>
                        </div>
                      </NavLink>
                    </Col>
                    <Col className="MenuIconWrapper">
                      <NavLink
                        className="text-decoration-none"
                        to="/InfoAplikasi"
                      >
                        <div>
                          <img src={infoicon} className="MenuIcon" />
                          <h5 className="TextMenu">Info Aplikasi</h5>
                        </div>
                      </NavLink>
                    </Col>
                  </Row>
                  {/* <a href="tel:113"> */}
                  <button className="Pannic-Button" onClick={handleShow2}>
                    Telepon Darurat
                  </button>
                  {/* </a> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="container-content">
              <div className="wrapper-menu" id="wrappermenu">
                <div className="MenuIconWrapper">
                  <div onClick={() => handleMenuClick("SIM")}>
                    <div>
                      <img src={simicon} className="MenuIcon"></img>
                      <h5 className="TextMenu">SIM</h5>
                    </div>
                  </div>
                </div>
                <div className="MenuIconWrapper">
                  <div onClick={() => handleMenuClick("SKCK")}>
                    <div>
                      <img src={skckicon} className="MenuIcon" />
                      <h5 className="TextMenu">SKCK</h5>
                    </div>
                  </div>
                </div>

                <div className="MenuIconWrapper">
                  <a
                    className="text-decoration-none"
                    href="https://dumaspresisi.polri.go.id/"
                  >
                    <div>
                      <img src={dupresicon} className="MenuIcon" />
                      <h5 className="TextMenu">Dumas Presisi</h5>
                    </div>
                  </a>
                </div>
                <div className="MenuIconWrapper">
                  <NavLink className="text-decoration-none" to="/KritikSaran">
                    <div>
                      <img src={kritiksaranicon} className="MenuIcon" />
                      <h5 className="TextMenu">Kritik Saran</h5>
                    </div>
                  </NavLink>
                </div>
                <div className="MenuIconWrapper">
                  <NavLink className="text-decoration-none" to="/Pengmas">
                    <div>
                      <img src={pelmasicon} className="MenuIcon" />
                      <h5 className="TextMenu">Pengaduan Masyarakat</h5>
                    </div>
                  </NavLink>
                </div>
                <div className="MenuIconWrapper">
                  <NavLink className="text-decoration-none" to="/Survei">
                    <div>
                      <img src={surveiicon} className="MenuIcon" />
                      <h5 className="TextMenu">Survei</h5>
                    </div>
                  </NavLink>
                </div>
                <div className="MenuIconWrapper">
                  <NavLink className="text-decoration-none" to="/InfoAplikasi">
                    <div>
                      <img src={infoicon} className="MenuIcon" />
                      <h5 className="TextMenu">Info Aplikasi</h5>
                    </div>
                  </NavLink>
                </div>

                <div className="MenuIconWrapper" id="responsiv-ipad">
                  <NavLink className="text-decoration-none" to="/Antrian">
                    <div>
                      <img src={antrianicon} className="MenuIcon" />
                      <h5 className="TextMenu">Antrian</h5>
                    </div>
                  </NavLink>
                </div>

                <div className="MenuIconWrapper" id="show-telepon">
                  <a className="text-decoration-none" href="tel:113">
                    <div className="menucall">
                      <img src={PanicCall} className="MenuIcon" />
                      <h5 className="TextMenu">Telepon Darurat</h5>
                    </div>

                    <span className="tooltips">
                      Gunakan telepon darurat jika sedang dalam masalah atau
                      keadaan darurat
                    </span>
                  </a>
                </div>
              </div>
              <a href="tel:113" id="show-telepon-button">
                <button className="Pannic-Button">Telepon Darurat</button>
              </a>
            </div>
          )}
        </div>
      </div>
      {/* </Container> */}
      {isMobile ? (
        <div className="NavigasiBottom">
          <Container>
            <Coba />
          </Container>
        </div>
      ) : (
        <div></div>
      )}
      {show && (
        <Modal show={show} onHide={handleClose} animation={false} centered>
          <Modal.Header closeButton>
            <Modal.Title>Informasi Penting</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Silahkan lengkapi data profil terlebih dahulu untuk menggunakan
            fitur yang ada pada Badak Berjaya
          </Modal.Body>
          <Modal.Footer>
            <div className="popupButton">
              <Button variant="primary" onClick={handleMenuProfil}>
                Menu Profil
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {show2 && (
        <Modal show={show2} onHide={handleClose2} animation={false} centered>
          <Modal.Header closeButton>
            <Modal.Title>Informasi Penting</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Gunakan telepon darurat jika sedang dalam masalah atau keadaan
            darurat
          </Modal.Body>
          <Modal.Footer>
            <div className="popupButton">
              <a href="tel:113">
                <Button variant="primary">Lanjutkan</Button>
              </a>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default Mainpage;
